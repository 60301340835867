/* Tailwind CSS imports */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Additional custom styles */

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

.transition-transform {
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.translate-y-0 {
  transform: translateY(0);
}

.translate-y-10 {
  transform: translateY(10px);
}

.section {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes wave {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(-15deg); }
  30% { transform: rotate(10deg); }
  45% { transform: rotate(-10deg); }
  60% { transform: rotate(6deg); }
  75% { transform: rotate(-6deg); }
  100% { transform: rotate(0deg); }
}

.waving-hand {
  width: 32px; /* Adjust size as needed */
  height: 32px; /* Adjust size as needed */
  display: inline-block;
  animation: wave 2s infinite;
  transform-origin: bottom center; /* Adjust the pivot point as needed */
}

/* Responsive Design */
@media (max-width: 768px) {
  .w-full {
    width: 100%;
  }
  
  .h-full {
    height: auto;
  }
  
  .rounded-2xl {
    border-radius: 1rem; /* Adjust as needed for smaller screens */
  }

  .p-6 {
    padding: 1.5rem; /* Adjust as needed for smaller screens */
  }

  .lg\:text-4xl {
    font-size: 2rem; /* Adjust as needed for smaller screens */
  }

  .text-xl {
    font-size: 1.25rem; /* Adjust as needed for smaller screens */
  }

  .gap-4 {
    gap: 1rem; /* Adjust as needed for smaller screens */
  }

  .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; /* Adjust as needed for smaller screens */
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-col {
    flex-direction: column;
  }

  .text-center {
    text-align: center;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .duration-300 {
    transition-duration: 300ms;
  }

  .scale-down {
    transform: scale(0.85); /* Example scale down for smaller screens */
  }
}

/* Additional styling for improved mobile experience */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Profile Image */
.profile-container {
  width: 120px; /* Adjust as needed */
  height: 120px; /* Adjust as needed */
}

.profile-image {
  border-width: 2px; /* Adjust border size */
}

@media (max-width: 768px) {
  .profile-container {
    width: 100px; /* Adjust as needed for smaller screens */
    height: 100px; /* Adjust as needed for smaller screens */
  }
}

.profile-image-wrapper {
  width: 100%;
  max-width: 12rem;
  padding-top: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid red; /* Temporary border for debugging */
}

.profile-image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid blue; /* Temporary border for debugging */
}

/* new */

.random-letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(-50px);
  animation: letterAnimation 0.5s forwards;
}

@keyframes letterAnimation {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(10px);
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}

.transition-opacity {
  transition: opacity 1s ease-in-out;
}

.transition-transform {
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.translate-y-10 {
  transform: translateY(10px);
}

.translate-y-0 {
  transform: translateY(0);
}


/* new effect* */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}

/* Ensure the backdrop blur effect is applied correctly */
.backdrop-blur-sm {
  backdrop-filter: blur(10px);
  background-color: rgba(211, 211, 211, 0.5); /* Light grey with opacity */ 
}

/* Ensure no overflow and proper rounded corners for the form */
.rounded-2xl {
  border-radius: 1.25rem; /* Example value */
}

.overflow-hidden {
  overflow: hidden;
}

/* Ensure enough space at the bottom of the page for mobile devices */
@media (max-width: 1024px) {
  .main-content {
    padding-bottom: 50px; /* Adjust this value to match the height of your bottom sidebar */
  }

  /* Additional styles to ensure sections do not overlap with the sidebar */
  #resume {
    margin-bottom: 50px; /* Adjust this to match the sidebar height */
  }
}
/* Ensure enough space at the bottom of the page for mobile devices */
@media (max-width: 1024px) {
  .main-content {
    padding-bottom: 50px; /* Adjust this value to match the height of your bottom sidebar */
  }

  /* Additional styles to ensure sections do not overlap with the sidebar */
  #projects {
    margin-bottom: 50px; /* Adjust this to match the sidebar height */
  }
}
/* Ensure enough space at the bottom of the page for mobile devices */
@media (max-width: 1024px) {
  .main-content {
    padding-bottom: 50px; /* Adjust this value to match the height of your bottom sidebar */
  }

  /* Additional styles to ensure sections do not overlap with the sidebar */
  #about {
    margin-bottom: 50px; /* Adjust this to match the sidebar height */
  }
}

